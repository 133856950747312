dmx.Component('moment', {

  initialData: {
    locale: 'en',
  },

  attributes: {
    locale: {
      type: String,
      default: 'en',
    },
  },

  methods: {
    setLocale (locale) {
      this._setLocale(locale);
    },
  },

  init () {
    moment.dmxComponent = this;
    this._setLocale(this.props.locale);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('locale')) {
      this._setLocale(this.props.locale);
    }
  },

  _setLocale (locale) {
    moment.locale(locale == 'auto' ? window.navigator.language : locale);
    this.set('locale', moment.locale());
  },

});
