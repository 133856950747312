(() => {

let _component;
const _now = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
const _today = moment().format('YYYY-MM-DD');
const _moment = (dt) => {
  // search for moment component
  if (_component === undefined) _component = document.querySelector('dmx-moment, [is="dmx-moment"]');
  // parse locale from moment component, this will register the current expression for changes
  if (_component) dmx.parse('locale', _component.dmxComponent);
  if (dt == 'now') dt = _now;
  if (dt == 'today') dt = _today;
  return moment(dt);
};

dmx.Formatters('string', {

  momentIsValid (date) {
    return _moment(date).isValid();
  },

  momentMin (...dates) {
    return moment.min(...dates).format('YYYY-MM-DD HH:mm:ss.SSS');
  },

  momentMax (...dates) {
    return moment.max(...dates).format('YYYY-MM-DD HH:mm:ss.SSS');
  },

  momentAdd (date, num, key) {
    return _moment(date).add(num, key).format('YYYY-MM-DD HH:mm:ss.SSS');
  },

  momentSubtract (date, num, key) {
    return _moment(date).subtract(num, key).format('YYYY-MM-DD HH:mm:ss.SSS');
  },

  momentFormat (date, format) {
    return _moment(date).format(format);
  },

  momentFromNow (date, noSuffix) {
    return _moment(date).fromNow(noSuffix);
  },

  momentFrom (date, date2, noSuffix) {
    return _moment(date).from(date2, noSuffix);
  },

  momentToNow (date, noSuffix) {
    return _moment(date).toNow(noSuffix);
  },

  momentTo (date, date2, noSuffix) {
    return _moment(date).to(date2, noSuffix);
  },

  momentCalendar (date, referenceDay) {
    return _moment(date).calendar(referenceDay);
  },

  momentDiff (date, date2, key, float) {
    return _moment(date).diff(date2, key, float);
  },

  momentDaysInMonth (date) {
    return _moment(date).daysInMonth();
  },

  momentToObject (date) {
    return _moment(date).toObject();
  },

  momentIsBefore (date, date2, key) {
    return _moment(date).isBefore(date2, key);
  },

  momentIsSame (date, date2, key) {
    return _moment(date).isSame(date2, key);
  },

  momentIsAfter (date, date2, key) {
    return _moment(date).isAfter(date2, key);
  },

  momentIsSameOrBefore (date, date2, key) {
    return _moment(date).isSameOrBefore(date2, key);
  },

  momentIsSameOrAfter (date, date2, key) {
    return _moment(date).isSameOrAfter(date2, key);
  },

  momentIsBetween (date, date1, date2, key) {
    return _moment(date).isBetween(date1, date2, key);
  },

  momentIsLeapYear (date) {
    return _moment(date).isLeapYear();
  },

});

})();